import React from 'react'
import Navebar from './Navebar'

const Home = () => {
  return (
    <div className="home-container">
      {/* <h1>fhsefbhsevfbwehbhfnbhub</h1> */}
        <Navebar />
    </div>
  )
}

export default Home