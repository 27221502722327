import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./hhh.module.css";

import { Row, Col } from "react-bootstrap";
import logo from "../assets/payroll_hero.png";
import logo7 from "../assets/payroll_scheduale-form.jpg";
import Icon1 from "../assets/icon1.svg";
import Icon2 from "../assets/icon 2.svg";
import Icon3 from "../assets/icon 3.svg";
import Image1 from "../assets/Payroll.webp";
import Image2 from "../assets/Personaldetails.webp";
import Image3 from "../assets/salarydetails.png";
import messageicon from "../assets/em1.svg";
import boxplusicon from "../assets/em2.svg";
import refreshicon from "../assets/em3.svg";
import img1 from "../assets/img1.webp";
import img2 from "../assets/img2.webp";
import img3 from "../assets/img3.webp";
import mail from "../assets/mail.webp";
import phone from "../assets/phone.webp";
import csimage1 from "../assets/newimage.jpg";
import csimage2 from "../assets/newimage2.png";
import csimage3 from "../assets/newimage3.jpg";
import csimage4 from "../assets/newimage4.jpeg";
import csimage5 from "../assets/newimage5.jpg";
import onboard from "../assets/onboard.png";
import report from "../assets/report.webp";
import support from "../assets/supporticons.svg";
import whatsapp from "../assets/whatsappicons.png";
import smartpaylogo from "../assets/Smart pay logo.png";

const Navebar = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [multipleclick, setmultipleclick] = useState(false);
  const tabCount = 3;
  const [progress, setProgress] = useState({
    "Tab 1": 1000,
    "Tab 2": 0,
    "Tab 3": 0,
  });
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    business_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 30);

    const mediaQuery = window.matchMedia("(min-width: 992px)");

    const handleScreenChange = (e) => {
      if (e.matches) {
        setShowDropdown(false);
      }
    };
    mediaQuery.addEventListener("change", handleScreenChange);
    return () => {
      mediaQuery.removeEventListener("change", handleScreenChange);
    };
    return () => clearInterval(interval);
  }, []);

  const insertdetails = async () => {
    if (
      multipleclick == false &&
      formData.phone != "" &&
      formData.name != "" &&
      formData.email != "" &&
      formData.city != "" &&
      formData.business_name != ""
    ) {
      setmultipleclick(true);
      try {
        const payload = { ...formData };

        const response = await axios.post(
          "http://64.227.160.105:8086/api/users",
          payload
        );
        setFormData({
          name: "",
          phone: "",
          email: "",
          city: "",
          business_name: "",
        });

        setToastMessage(response.data.message);
        setShowToast(true);
      } catch (error) {
        setToastMessage(error.response.data.message);
        setShowToast(true);
      }
      setmultipleclick(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    if (progress >= 100) {
      setCurrentTab((prev) => (prev + 1) % tabCount);
      setProgress(0);
    }
  }, [progress]);

  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
    setProgress(0);
  };

  return (
    <nav>
      <section className="invoice_sub-header-wrapper">
        <div className="wrapper">
          <div className="footer_link-list">
            <Row>
              <Col xs="8" lg="4">
                <div className="logo_samrt_pay">
                  <div>
                    <img className="smart_pay_logo" src={smartpaylogo} />
                  </div>
                  <div className="smart_pay_design">
                    <span>
                      <b className="smartpay_logo">SMART PAY</b>
                      <p className="payroll_benefits_hr">
                        payroll .Benefits .HR
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              <div className="col-xs-1x col-lg-1 d-none d-lg-block head_title">
                <a href="#free-demo-smart-pay" className="footer_link">
                  Home
                </a>
              </div>
              {/* <div className="col-xs-1x col-lg-1 d-none d-lg-block">
                <a href="#free-demo-pricing" className="footer_link">
                  Pricing
                </a>
              </div> */}
              <div className="col-xs-1x col-lg-1 d-none d-lg-block head_title">
                <a href="#free-demo-stress-resources" className="footer_link">
                  Product
                </a>
              </div>
              <div className="col-xs-1x col-lg-1 d-none d-lg-block head_title">
                <a href="#free-demo-stree-free" className="footer_link">
                  About
                </a>
              </div>

              <Col xs="4" className="col-xs-4x d-lg-none menu_bar_details">
                <div onClick={toggleDropdown}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-list"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                    />
                  </svg>
                </div>
              </Col>

              {showDropdown && (
                <div className="dropdown-menu-xs">
                  <a
                    href="#free-demo-smart-pay"
                    className="footer_link"
                    onClick={handleLinkClick}
                  >
                    Home
                  </a>
                  {/* <a
                    href="#free-demo-pricing"
                    className="footer_link"
                    onClick={handleLinkClick}
                  >
                    Pricing
                  </a> */}
                  <a
                    href="#free-demo-stress-resources"
                    className="footer_link"
                    onClick={handleLinkClick}
                  >
                    Product
                  </a>
                  <a
                    href="#free-demo-stree-free"
                    className="footer_link"
                    onClick={handleLinkClick}
                  >
                    About
                  </a>
                </div>
              )}
            </Row>
          </div>
        </div>
      </section>
      <div className="hero__pos_content">
        <section
          className="hero_pos hero-home payroll_hero container"
          id="free-demo-smart-pay"
        >
          <Row>
            <Col xs="12" lg="6" className="order-xs-2">
              <div className="wrapper">
                <div className="hero__pos_contents_img">
                  <div class="hero__pos_content">
                    <a href="javascript:void(0)">
                      <div className="logo_samrt_pay">
                        <div>
                          <img className="smart_pay_logo" src={smartpaylogo} />
                        </div>
                        <div className="smart_pay_design">
                          <span>
                            <b className="smartpay_logo" >SMART PAY</b>
                            <p className="payroll_benefits_hr">
                            payroll .Benefits .HR
                            </p>
                          </span>
                        </div>
                      </div>
                    </a>
                    <h1 className="hero__title">
                      UK Payroll, automated to save time
                    </h1>
                    <p className="hero_paragph">
                      The simple, efficient and &amp; error-free way tp pay your
                      team &amp; Payruns in minutes,simple pricing,expert
                      support.
                    </p>
                    <a href="#free-demo-form" className="btn btn__primary">
                      Explore SmartPay
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" lg="6" className="order-xs-1">
              <div className="hero__pos_img">
                <a href="javascript:void(0)"></a>
                <picture>
                  <source srcset={logo} media="(max-width: 423px)" />
                  <img
                    src={logo}
                    alt="Attendance &amp; Payroll Simplified"
                    title="Attendance &amp; Payroll Simplified"
                    width="100%"
                    height="auto"
                  />
                </picture>
              </div>
            </Col>
          </Row>
        </section>
      </div>

      <section class="section_layout228">
        <div class="padding-global">
          <div class="container-large">
            <div class="padding-section-large">
              <div class="margin-bottom margin-xxlarge">
                <h2
                  id="w-node-_0f4facdc-4fb3-2d13-1e85-387c7c7337fc-7c7337f7"
                  class="text-align-center h2_pos"
                >
                  Why SmartPay
                </h2>
              </div>
              <div className="parent-container">
                <div class="w-layout-grid layout_component1">
                  <div class="layout_item ">
                    <div class="margin-bottom margin-small">
                      <img
                        src={Icon1}
                        loading="lazy"
                        alt="Why Onfolk icon 1"
                        class="icon-1x1-large"
                      />
                    </div>
                    <div class="margin-bottom margin-xsmall">
                      <h3 class="heading-style-h4">Integrated</h3>
                    </div>
                    <p class="text-size-medium">
                      With HMRC, all major pension schemes, and accounting
                      softwares
                    </p>
                  </div>
                  <div class="layout_item">
                    <div class="margin-bottom margin-small">
                      <img
                        src={Icon2}
                        loading="lazy"
                        width="Auto"
                        alt=""
                        class="icon-1x1-large"
                      />
                    </div>
                    <div class="margin-bottom margin-xsmall">
                      <h3 class="heading-style-h4">Expert customer support</h3>
                    </div>
                    <p class="text-size-medium">
                      SmartPay is there for you. <br />
                      With fast and accurate responses from payroll experts.
                    </p>
                  </div>
                  <div class="layout_item">
                    <div class="margin-bottom margin-small">
                      <img
                        src={Icon3}
                        loading="lazy"
                        alt=""
                        class="icon-1x1-large"
                      />
                    </div>
                    <div class="margin-bottom margin-xsmall">
                      <h3 class="heading-style-h4">Seriously time-saving</h3>
                    </div>
                    <p class="text-size-medium">
                      1 min to run payroll &amp; 3 mins to set up new starters.
                      <br />
                      Automated tax code notices and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-large with-divider"></div>
        </div>
      </section>

      <section className="efficient_details" id="free-demo-pricing">
        <div className="background-color-white">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large align-center">
                      <div class="margin-bottom margin-xxlarge">
                        <h2
                          id="w-node-_0f4facdc-4fb3-2d13-1e85-387c7c7337fc-7c7337f7"
                          class="text-align-center h2_pos"
                        >
                          One efficient system for all your people
                        </h2>
                      </div>
                    </div>
                    <div className="max-width-medium align-center">
                      <p className="text-size-medium">
                        Enable growth without painful admin. Eliminate
                        out-of-sync and duplicate information.
                      </p>
                      <div className="margin-top margin-small">
                        <div className="button-group is-center"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  <div className="tabs w-tabs">
                    <div className="tabs_menu w-tab-menu" role="tablist">
                      {[
                        "1 min payroll",
                        "Your people info, centralised",
                        "Reporting across HR & pay data",
                      ].map((title, index) => (
                        <a
                          key={index}
                          data-w-tab={`Tab ${index + 1}`}
                          className={`tabs_tab w-inline-block w-tab-link ${
                            currentTab === index ? "w--current" : ""
                          }`}
                          onClick={() => handleTabClick(index)}
                          role="tab"
                          aria-selected={currentTab === index}
                        >
                          <div className="tab-button-progress-bar">
                            <div
                              className="tab-button-progress"
                              style={{
                                width: `${progress}%`,
                                transition: "width 0.0s ease",
                              }}
                            ></div>
                          </div>
                          <div className="tab-button-content">
                            <div className="tab-button-title">
                              <h3 className="heading-style-h5">{title}</h3>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="tabs_content w-tab-content">
                      {currentTab === 0 && (
                        <div
                          data-w-tab="Tab 1"
                          className="tabs_pane w-tab-pane"
                        >
                          <img
                            src={Image1}
                            alt="Payroll Image"
                            className="tabs_image"
                          />
                        </div>
                      )}
                      {currentTab === 1 && (
                        <div
                          data-w-tab="Tab 2"
                          className="tabs_pane w-tab-pane"
                        >
                          <img
                            src={Image2}
                            alt="Employee Info Image"
                            className="tabs_image"
                          />
                        </div>
                      )}
                      {currentTab === 2 && (
                        <div
                          data-w-tab="Tab 3"
                          className="tabs_pane w-tab-pane"
                        >
                          <img
                            src={Image3}
                            alt="Reporting Image"
                            className="tabs_image"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="background-color-green"
        id="free-demo-stress-resources"
      >
        <div className="padding-global">
          <div className="container-large">
            <div className="padding-section-large">
              <Swiper
                modules={[Navigation]}
                spaceBetween={64}
                slidesPerView={1}
                navigation={{
                  prevEl: "#left-button",
                  nextEl: "#right-button",
                }}
                loop={true}
                grabCursor={true}
                className="basic-swiper"
              >
                <SwiperSlide>
                  <div className="swiper_details">
                    <div className="basic-swiper-slide-content order-xs-1">
                      <img
                        loading="lazy"
                        alt="Geoff Rellis"
                        src={csimage5}
                        sizes="(max-width: 479px) 82vw, (max-width: 767px) 86vw, (max-width: 991px) 41vw, 40vw"
                        className="clipping-mask"
                      />
                    </div>
                    <div className="testimonial_content-top order-xs-2">
                      <h3 className="heading-style-h3 is-serif">
                        "SmartPay has given 25 hours / week back to our senior
                        management team to focus on company culture and
                        mentorship.”
                      </h3>
                      <div className="testimonial_info-link">
                        <div className="testimonial_client-info">
                          <div className="text-weight-medium">Geoff Rellis</div>
                          <div className="text-color-black-75">
                            CEO, Good Life Gymnastics
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper_details">
                    <div className="basic-swiper-slide-content">
                      <img
                        loading="lazy"
                        alt="Freddie Green"
                        src={csimage2}
                        className="clipping-mask"
                      />
                    </div>
                    <div className="testimonial_content-top">
                      <h3 className="heading-style-h3 is-serif">
                        “Payroll and HR is no longer a problem I need to solve.
                        Instead I get more headspace to focus on other big
                        pieces of work.”
                      </h3>
                      <div className="testimonial_info-link">
                        <div className="testimonial_client-info">
                          <div className="text-weight-medium">
                            Freddie Green
                          </div>
                          <div className="text-color-black-75">
                            Co-founder, minimum
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper_details">
                    <div className="basic-swiper-slide-content">
                      <img
                        loading="lazy"
                        alt="Freddie Green"
                        src={csimage3}
                        className="clipping-mask"
                      />
                    </div>
                    <div className="testimonial_content-top">
                      <div className="heading-style-h3 is-serif">
                        “When I unexpectedly caught Covid, my colleague ran
                        payroll with no issues. With our old processes the
                        situation would have been very challenging.”
                      </div>
                      <div className="testimonial_info-link">
                        <div className="testimonial_client-info">
                          <div className="text-weight-medium">Emma Dixon</div>
                          <div className="text-color-black-75">
                            Head of Finance &amp; Data, Juno Legal
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper_details">
                    <div className="basic-swiper-slide-content">
                      <img
                        loading="lazy"
                        alt="Freddie Green"
                        src={csimage4}
                        className="clipping-mask"
                      />
                    </div>
                    <div className="testimonial_content-top">
                      <div className="heading-style-h3 is-serif">
                        “Payroll has never been a concern since day one.
                        SmartPay take care of everything for you, so you can
                        focus on the important things”
                      </div>
                      <div className="testimonial_info-link">
                        <div className="testimonial_client-info">
                          <div className="text-weight-medium">
                            {" "}
                            Valerio Magliulo
                          </div>
                          <div className="text-color-black-75">
                            CEO, Abatable
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper_details">
                    <div className="basic-swiper-slide-content">
                      <img
                        loading="lazy"
                        alt="Freddie Green"
                        src={csimage1}
                        className="clipping-mask"
                      />
                    </div>
                    <div className="testimonial_content-top">
                      <div className="heading-style-h3 is-serif">
                        “I like being in control of my payroll, without the back
                        and forth of using an accountant.”
                      </div>
                      <div className="testimonial_info-link">
                        <div className="testimonial_client-info">
                          <div className="text-weight-medium">
                            Rebecca De Beukelaer
                          </div>
                          <div className="text-color-black-75">
                            Marketing &amp; Operations Manager, SearchLand
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <div className="swiper-arrow-wrapper">
                  <div id="left-button" class="slider-button-left">
                    <div class="icon-embed-xsmall rotate-180 w-embed">
                      <svg
                        width=" 100%"
                        height=" 100%"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 1.93945L14.5303 7.46978V8.53044L9 14.0608L7.93934 13.0001L12.1893 8.75011H1.25V7.25011H12.1893L7.93934 3.00011L9 1.93945Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div id="right-button" class="slider-button-right">
                    <div class="icon-embed-xsmall w-embed">
                      <svg
                        width=" 100%"
                        height=" 100%"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 1.93945L14.5303 7.46978V8.53044L9 14.0608L7.93934 13.0001L12.1893 8.75011H1.25V7.25011H12.1893L7.93934 3.00011L9 1.93945Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section
        class="builtsms_india_wrapper stressfree_wrapper section_spacing_y"
        id="free-demo-stree-free"
      >
        <div class="wrapper">
          <h2 class="h2_pos">
            Stress-free employee management software for all your needs
          </h2>
          <div class="builtall_features streesfree_features">
            <div class="built_feature_steps">
              <img class="m-icon easystaff_icon" src={onboard} />
              <h3 class="streefree_title">Easy staff onboarding</h3>
              <p class="prlbuiltstep_title">
                Dump tedious process. Onboard and permanently store employee
                data
              </p>
            </div>
            <div class="built_feature_steps">
              <img class="m-icon easystaff_icon" src={report} />
              <h3 class="streefree_title">Customised staff reports</h3>
              <p class="prlbuiltstep_title">
                Get your staff’s and their performance reports the way you want
                it
              </p>
            </div>
            <div class="built_feature_steps">
              <img class="m-icon easystaff_icon" src={support} />
              <h3 class="streefree_title">24/7 Expert service support</h3>
              <p class="prlbuiltstep_title">
                We’re here to help! Connect with us whenever you want
              </p>
            </div>
            <div class="built_feature_steps">
              <img class="m-icon easystaff_icon" src={whatsapp} />
              <h3 class="streefree_title">WhatApp Based Updates</h3>
              <p class="prlbuiltstep_title">
                Get daily attendance reports on your registered WhatsApp number
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="background-color-white support_details_page">
        <div class="padding-global">
          <div class="container-large">
            <div class="padding-section-large">
              <div class="margin-bottom margin-large">
                <div class="customer-support_image-stack">
                  <div class="customer-support_image-stack-wrapper">
                    <img
                      src={img1}
                      loading="lazy"
                      alt=""
                      class="icon-1x1-large"
                    />
                  </div>
                  <div class="customer-support_image-stack-wrapper">
                    <img
                      src={img2}
                      loading="lazy"
                      alt=""
                      class="icon-1x1-xlarge"
                    />
                  </div>
                  <div class="customer-support_image-stack-wrapper">
                    <img
                      src={img3}
                      loading="lazy"
                      alt=""
                      class="icon-1x1-large"
                    />
                  </div>
                </div>
              </div>
              <div class="margin-bottom margin-xxlarge">
                <h2
                  id="w-node-_7fb97b06-75ae-bc92-30bb-e359ac5c701b-ac5c700e"
                  class="text-align-center h2_pos"
                >
                  The best customer support
                </h2>
              </div>
              <div class="w-layout-grid layout_component1">
                <div class="layout_item">
                  <div class="margin-bottom margin-small">
                    <img
                      src={messageicon}
                      loading="lazy"
                      alt=""
                      class="icon-1x1-large"
                    />
                  </div>
                  <div class="margin-bottom margin-xsmall">
                    <h3 class="heading-style-h4">
                      Expert support for your Payroll and HR
                    </h3>
                  </div>
                  <p class="text-size-medium">
                    Our team are payroll experts and members of the CIPP. We
                    understand the importance of getting it right.
                  </p>
                </div>
                <div class="layout_item">
                  <div class="margin-bottom margin-small">
                    <img
                      src={boxplusicon}
                      loading="lazy"
                      alt="Onfolk customer support icon 2"
                      class="icon-1x1-large"
                    />
                  </div>
                  <div class="margin-bottom margin-xsmall">
                    <h3 class="heading-style-h4">
                      Accurate and timely responses
                    </h3>
                  </div>
                  <p class="text-size-medium">
                    We get the frustration of waiting for answers on payday. So
                    we offer faster support when it’s yours.
                  </p>
                </div>
                <div class="layout_item">
                  <div class="margin-bottom margin-small">
                    <img
                      src={refreshicon}
                      loading="lazy"
                      alt="Onfolk customer support icon 3"
                      class="icon-1x1-large"
                    />
                  </div>
                  <div class="margin-bottom margin-xsmall">
                    <h3 class="heading-style-h4">
                      Acting on your feedback to improve
                    </h3>
                  </div>
                  <p class="text-size-medium">
                    Every week, SmartPay gets better because of our customers.
                    We take our customer’s feedback seriously to consistently
                    improve your experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container-large with-divider"></div>
        </div>
      </section>

      <section class="scheduale" id="free-demo-form">
        <div class="wrapper" id="free-demo-form">
          <div class="scheduale_row">
            <div class="scheduale_col">
              <h3 class="scheduale_form_titel ">Schedule a free demo</h3>
              <p class="scheduale_form_dec">
                Get in touch with our team to clarify your queries
              </p>

              <form autoComplete="off">
                <div className="form-group">
                  <label htmlFor="name" className="field_box">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your first name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="phone_email_input_details">
                  <div className="form-group">
                    <label htmlFor="phone" className="field_box">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control details_phoneemail"
                      id="phone"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group emailphone">
                    <label htmlFor="email" className="field_box">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control details_phoneemail"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="city" className="field_box">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="business_name" className="field_box">
                    Business Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="business_name"
                    name="business_name"
                    placeholder="Enter your business name"
                    value={formData.business_name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </form>

              <div className="submit_button">
                <button
                  type="submit"
                  className="btn btn-primary submit_button_detail "
                  onClick={insertdetails}
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              id="hbspt-form-386c9ecc-21f3-4d46-b25f-5428d632667b"
              class="hbspt-form"
              data-hs-forms-root="true"
            >
              <img
                id="hs-form-iframe-0"
                class="hs-form-iframe"
                style={{
                  position: "static",
                  border: "none",
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={logo7}
              ></img>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="landing_footer section_spacing_footer">
          <div class="wrapper">
            <div class="scheduale_row">
              <div class="scheduale_col">
                <a>
                  <div className="logo_samrt_pay">
                    <div>
                      <img className="smart_pay_logo" src={smartpaylogo} />
                    </div>
                    <div className="smart_pay_design">
                      <span>
                        <b className="smartpay_logo">SMART PAY</b>
                        <p className="payroll_benefits_hr">
                        payroll .Benefits .HR
                        </p>
                      </span>
                    </div>
                  </div>
                </a>
                <p class="footer_category_name contact-us">Contact Us</p>
                <div>
                  <a class="footer_category_name">
                    <img loading="lazy" src={mail} alt="mail" />
                    sales@avelator.com
                  </a>
                  <a class="footer_category_name">
                    <img loading="lazy" src={phone} alt="phone" />
                    +91 9095719408
                  </a>
                </div>
              </div>
              <div class="scheduale_col address_pti">
                <p class="footer_category_name">
                  Smartpay solution private Limited
                </p>
                <a class="footer_category_name">
                  201 F, Second floor, Palathurai road, Madukkarai Coimbatore,
                  Tamilnadu 641105
                </a>
              </div>
            </div>
            <div class="footer_contact_wrapper">
              <div class="footer_contact">
                <div class="footer_contact_social">
                  <a
                    class="footer_icon_link"
                    href="https://in.linkedin.com/company/avelator-solutions-pvt-ltd"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28.63 28.63"
                    >
                      <path
                        id="Path_1412"
                        data-name="Path 1412"
                        d="M16.565,1.5A14.315,14.315,0,1,0,30.88,15.815,14.315,14.315,0,0,0,16.565,1.5ZM13.107,9.365a1.793,1.793,0,0,1-1.85,1.73,1.793,1.793,0,0,1-1.85-1.73,1.793,1.793,0,0,1,1.85-1.73A1.793,1.793,0,0,1,13.107,9.365Zm-3.447,3h3.162V21.95H9.66Zm5.091,0h3.162V13.6a3.922,3.922,0,0,1,3.194-1.422c2.768,0,3.61,1.488,3.637,4.717V21.95H21.582c0-.625,0-1.253-.009-1.882h0c-.006-.891-.012-1.781-.006-2.661.008-1.148.019-2.629-1.725-2.629-1.542,0-1.929,1.137-1.929,2.269v4.9H14.752Z"
                        transform="translate(-2.25 -1.5)"
                        fill="#fff"
                        fill-rule="evenodd"
                      ></path>
                    </svg>{" "}
                  </a>
                  <a
                    class="footer_icon_link"
                    href="https://www.instagram.com/avelatorsolutions/"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 2.99622C3.34315 2.99622 2 4.33936 2 5.99622V13.9962C2 15.6531 3.34315 16.9962 5 16.9962H13C14.6569 16.9962 16 15.6531 16 13.9962V5.99622C16 4.33936 14.6569 2.99622 13 2.99622H5ZM0 5.99622C0 3.23479 2.23858 0.996216 5 0.996216H13C15.7614 0.996216 18 3.23479 18 5.99622V13.9962C18 16.7576 15.7614 18.9962 13 18.9962H5C2.23858 18.9962 0 16.7576 0 13.9962V5.99622Z"
                        fill="#fff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 8C7.89543 8 7 8.89543 7 10C7 11.1046 7.89543 12 9 12C10.1046 12 11 11.1046 11 10C11 8.89543 10.1046 8 9 8ZM5 10C5 7.79086 6.79086 6 9 6C11.2091 6 13 7.79086 13 10C13 12.2091 11.2091 14 9 14C6.79086 14 5 12.2091 5 10Z"
                        fill="#fff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.5 4.5C14.0523 4.5 14.5 4.94772 14.5 5.5V5.524C14.5 6.07628 14.0523 6.524 13.5 6.524C12.9477 6.524 12.5 6.07628 12.5 5.524V5.5C12.5 4.94772 12.9477 4.5 13.5 4.5Z"
                        fill="#fff"
                      ></path>
                    </svg>{" "}
                  </a>
                  <a
                    class="footer_icon_link"
                    href="https://in.linkedin.com/company/avelator-solutions-pvt-ltd"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28.63 28.493"
                    >
                      <path
                        id="Path_1415"
                        data-name="Path 1415"
                        d="M138.554,29.993a14.313,14.313,0,1,0-4.483-.079V20.193H130.43v-4.04h3.641V13.073c0-3.5,2.14-5.438,5.418-5.438a22.617,22.617,0,0,1,3.211.273v3.44h-1.809a2.047,2.047,0,0,0-2.337,2.184v2.621h3.978l-.637,4.04h-3.341Z"
                        transform="translate(-122.25 -1.5)"
                        fill="#fff"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <p class="footer_copyright">
              © 2024 All rights Reserved by Smartpay solutions Pvt. Ltd. •
              <a>Privacy</a>•<a>Terms</a>
            </p>
          </div>
        </div>
      </footer>

      {showToast && (
        <div
          className="toast show position-fixed"
          style={{
            top: "20px",
            right: "20px",
            zIndex: "1050",
            backgroundColor: "#1f796e",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px",
          }}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div
            className="toast-body"
            style={{
              color: "white",
              fontWeight: "500",
              backgroundColor: "#1f796e",
              borderRadius: "20px",
            }}
          >
            {toastMessage}
          </div>
          <button
            style={{ color: "white" }}
            type="button"
            className="ml-2 mb-1 close"
            onClick={() => setShowToast(false)}
            aria-label="Close"
          >
            <span aria-hidden="true" style={{ fontSize: "28px" }}>
              &times;
            </span>
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navebar;
