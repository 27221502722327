// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hhh_invoice-sub-header__MdP8B{
    text-align: center;
}
.hhh_hero__pos_content__K1RY9{
    height: 32rem;
    background-color: rgb(44 119 207 / var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/hhh.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,wDAAwD;IACxD,kBAAkB;AACtB","sourcesContent":[".invoice-sub-header{\r\n    text-align: center;\r\n}\r\n.hero__pos_content{\r\n    height: 32rem;\r\n    background-color: rgb(44 119 207 / var(--tw-bg-opacity));\r\n    --tw-bg-opacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoice-sub-header": `hhh_invoice-sub-header__MdP8B`,
	"hero__pos_content": `hhh_hero__pos_content__K1RY9`
};
export default ___CSS_LOADER_EXPORT___;
